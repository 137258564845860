<template lang="html">
    <router-link v-if="color === 'white'" to="/" class="ps-logo ps-logo--white">
        <img src="@/static/img/logo-white.png" alt="Logo" />
    </router-link>
    <router-link v-else to="/" class="ps-logo">
        <img src="@/static/img/logo-white.png" alt="Logo" />
    </router-link>
</template>

<script>
export default {
    name: 'Logo',
    props: {
        color: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
	.ps-logo img {
		width: 100%;
		height: auto;
	}
</style>
